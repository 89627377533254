(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/banner_actions/quickplay/assets/javascripts/init.js') >= 0) return;  svs.modules.push('/components/banner_actions/quickplay/assets/javascripts/init.js');
"use strict";


const {
  addClickEvents,
  keepModalOpen
} = svs.banner_actions.quickplay.initActions;
addClickEvents();
keepModalOpen();

 })(window);